import React, { useState } from "react";
import { motion } from "framer-motion";
const MissionVision = () => {
  const [isVisionClicked, setIsVisionClicked] = useState(true);
  const [isMissionClicked, setIsMissionClicked] = useState(false);
  return (
    <div className={` py-20 xl:py-36 xl:max-h-[950px] duration-300 relative`}>
      <div className="xl:max-w-[1240px] 2xl:max-w-[1520px] mx-auto">
        <div className="flex w-full justify-between items-center relative px-5 md:px-20 xl:px-0">
          <div className="xl:left-32 relative flex justify-center">
            <div
              className={`absolute h-[40px] w-[40px] rounded-full ${
                isVisionClicked
                  ? "bg-lightGreenText"
                  : "border-2 border-lightGreenText bg-bgDarkGreen"
              } top-11 cursor-pointer`}
              onClick={() => {
                setIsVisionClicked(true);
                setIsMissionClicked(false);
              }}
            />
            <span className={`mb-10 text-[16px] text-white`}>The Vision</span>
            <div
              className={`absolute h-[40px] w-[40px] rounded-full  ${
                isVisionClicked && "animate-ping bg-lightGreenText"
              } top-11 cursor-pointer`}
              onClick={() => {
                setIsVisionClicked(true);
                setIsMissionClicked(false);
              }}
            />
          </div>
          <div className="xl:right-32 relative flex justify-center">
            <div
              className={`absolute h-[40px] w-[40px] rounded-full ${
                isMissionClicked && "bg-lightGreenText animate-ping"
              } top-11 cursor-pointer`}
              onClick={() => {
                setIsVisionClicked(false);
                setIsMissionClicked(true);
              }}
            />
            <span className={`mb-10 text-[16px] text-white`}>The Mission</span>
            <div
              className={`absolute h-[40px] w-[40px] rounded-full ${
                isMissionClicked
                  ? "bg-lightGreenText"
                  : "border-2 border-lightGreenText bg-bgDarkGreen"
              } top-11 cursor-pointer`}
              onClick={() => {
                setIsVisionClicked(false);
                setIsMissionClicked(true);
              }}
            />
          </div>
        </div>
      </div>
      <div className={`w-full h-[1px] bg-lightGreenText duration-300`}></div>
      <div className="xl:max-w-[1240px] py-20 px-5 xl:px-0 w-full mx-auto text-lightGreenText">
        {isVisionClicked && (
          <motion.div
            className={`mt-20 flex flex-col text-center justify-center items-center gap-10`}
            initial={{ x: -750 }}
            animate={{ x: 0 }}
          >
            <span className="text-[25px] md:text-[55px]">
              To be the top supplier and manufacturing of edible{" "}
              <span className=" text-[#ACFF80]">Eco-friendly</span> materials
              used for everyday life while reducing waste and helping the local
              farmers.
            </span>
            <div className="max-w-[800px] w-full mx-auto flex flex-col gap-10">
              <span className="text-[16px] md:text-[20px] font-light lato">
                “We stand unwavering in our commitment to become the premier
                supplier of edible, eco-friendly materials, fostering
                sustainability, reducing waste, and empowering local farmers for
                a brighter, greener future.”
              </span>
              {/* <span className='lato italic text-[16px] font-light'>- Jerry Siena, Chief Executive Officer (CEO)</span> */}
              <span className="lato italic text-[16px] font-light">
                - Chief Executive Officer (CEO)
              </span>
            </div>
          </motion.div>
        )}
        {isMissionClicked && (
          <motion.div
            className=" mt-20 flex flex-col text-center  justify-center items-center gap-10"
            initial={{ x: 750 }}
            animate={{ x: 0 }}
          >
            <span className="text-[25px] md:text-[50px]">
              To surpass our clients expectations in quality, delivery, and cost
              through continuous improvement and customer interaction.
            </span>
            <div className="max-w-[800px] w-full mx-auto flex flex-col gap-10">
              <span className="text-[16px] md:text-[20px] font-light lato">
                “We are resolute in our mission to exceed expectations in
                sustainability, forging a green path through continual
                improvement, and global environmental commitment.”
              </span>
              <span className="lato italic text-[16px]">
                - Gabriele Salvador, Chief Operations Officer (COO)
              </span>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default MissionVision;
