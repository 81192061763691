import './App.css';
import { Route, Routes } from 'react-router-dom';
import IndexLayout from './components/layout';
import LandingPage from './components/landing/LandingPage';
function App() {
  const Landing = () => {
    return (
      <Routes>
        <Route path='/' element={<LandingPage/>} />
      </Routes>
    )
  }
  return (
    <IndexLayout>
      {<Landing/>}
    </IndexLayout>
  );
}

export default App;
