import React from "react";
import MarqueeLogos from "./MarqueeLogos";
import { TiSocialFacebook, TiSocialInstagram } from "react-icons/ti";
// import { Divider } from "antd";
// import shoppeeLogo from "../../assets/logos/shoppee_logo.png";
// import lazadaLogo from "../../assets/logos/lazada_logo.png";
import productImage1 from "../../assets/images/sutla-product-3.png";
const Products = () => {
  return (
    <div className=" bg-bgLightGreen py-10 w-full" id="Products">
      <MarqueeLogos />
      <div className="2xl:max-w-[1520px] xl:max-w-[1240px] flex flex-col my-24 xl:my-44 w-full mx-auto gap-5 px-5 lg:grid lg:grid-cols-2 xl:grid-cols-3 justify-center items-center">
        <div className="flex flex-col gap-16 w-full lg:col-span-2 xl:col-span-1">
          <div className="flex flex-col xl:max-w-[450px]">
            <div className="flex flex-col">
              <span className="text-[40px] xl:text-[50px] mb-[-25px] disable-selection">
                NONI HAIR{" "}
              </span>
              <span className="text-[40px] xl:text-[50px] disable-selection">
                COLOUR SHAMPOO
              </span>
            </div>
            <span className="text-[20px]">
              BSY Noni Black Hair Magic contains pure extracts of genuine
              Hawaiian Noni fruit, which is medicinally the most effective
              variety of Morinda Citrifolia of the genus Morinda species.
            </span>
          </div>
          {/* <div className="flex flex-col gap-5 justify-center items-center xl:justify-start xl:items-start">
            <span className="text-[40px] font-bold mb-[-20px]">₱ 88.88</span>
            <Divider className="my-[0px] bg-bgDarkGreen" />
            <div className="w-[150px] text-center text-lightGreenText py-3 bg-bgDarkGreen">
              Purchase Now
            </div>
          </div> */}
          <div className="flex gap-5 items-center mb-5 md:mb-0">
            {/* <img src={shoppeeLogo} alt="" className="h-[35px] object-cover" />
            <img src={lazadaLogo} alt="" className="h-[30px] object-cover" /> */}
            <div className="flex justify-center items-center relative">
              <div className="bg-black animate-ping w-[25px] h-[25px] absolute rounded-full opacity-50" />
              <TiSocialFacebook
                className="text-[35px] object-cover cursor-pointer z-10 hover:text-textAvocadoLight duration-150"
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/BSYPhilippines",
                    "_blank"
                  )
                }
              />
            </div>
            <div className="flex justify-center items-center relative">
              <div className="bg-black animate-ping w-[25px] h-[25px] absolute rounded-full opacity-50" />
              <TiSocialInstagram
                className="text-[35px] object-cover cursor-pointer z-10 hover:text-textAvocadoLight duration-150"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/bsyphilippines?igsh=MTkwc3pnM2dlcGs4cg==",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center items-center">
          <img src={productImage1} alt="" />
        </div>
        <div className="flex flex-col justify-center items-center md:justify-start md:items-start">
          <span className="text-[40px] md:text-[50px] font-bold">
            ABOUT PRODUCT
          </span>
          <span className="text-[18px] text-justify md:text-left">
            BSY Noni Black Hair Magic is a shampoo-based hair color that color
            your hair in 5-Minutes. It is a product developed by years-long
            research of health experts and scientists. It is powered by the
            highly potent extracts of the BSY Noni Enzyme Extract. Besides
            blackening hair, it also has anti-dandruff and nourishing properties
            which can supply essential nutrients to hair roots and effectively
            prevent hair loss. The Noni Fruit Extracts present in the Shampoo
            fights to repair damaged hair and scalp.
          </span>
        </div>
      </div>
      <MarqueeLogos />
    </div>
  );
};

export default Products;
