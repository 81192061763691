import bgImageDrawer from "../assets/images/background-2.webp";
import bsyLogo from "../assets/logos/bsy-logo.png";
import cultivateLogo from "../assets/logos/cultivate-logo.png";
// import sutlaLogo from "../assets/logos/sutla-logo.png";
import bsyLogoFotter from "../assets/logos/bsy-logo-footer.png";
import cultivateLogoFooter from "../assets/logos/cultivate-logo-footer.png";
// import sutlaLogoFooter from "../assets/logos/sutla-logo-footer.png";
export const navItems = [
  {
    key: 1,
    navItem: "Home",
  },
  // {
  //   key: 2,
  //   navItem: "About Us",
  // },
  {
    key: 2,
    navItem: "Products",
  },
  // {
  //   key: 4,
  //   navItem: "Services",
  // },
  {
    key: 3,
    navItem: "Roadmap",
  },
  {
    key: 4,
    navItem: "Contact Us",
  },
];
export const drawerItems = [
  {
    key: 1,
    item: "Home",
  },
  // {
  //   key: 2,
  //   item: "About Us",
  // },
  {
    key: 2,
    item: "Products",
  },
  // {
  //   key: 4,
  //   item: "Services",
  // },
  {
    key: 3,
    item: "Roadmap",
  },
  {
    key: 4,
    item: "Contact Us",
  },
];
export const backgroundImageDrawerStyle = {
  backgroundImage: `url(${bgImageDrawer})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "repeat",
};
export const marqueeItems = [
  {
    key: 1,
    logo: bsyLogo,
    height: 30,
  },
  {
    key: 2,
    logo: cultivateLogo,
    height: 30,
  },
  // {
  //     key: 3,
  //     logo: sutlaLogo,
  //     height: 30,
  // }
];
export const footerImageItems = [
  {
    key: 1,
    logo: cultivateLogoFooter,
    height: 77,
  },
  // {
  //   key: 2,
  //   logo: sutlaLogoFooter,
  //   height: 82,
  // },
  {
    key: 3,
    logo: bsyLogoFotter,
    height: 78,
  },
];
