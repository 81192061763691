import React, { useLayoutEffect, useState } from "react";
import { MdArrowRightAlt } from "react-icons/md";
import { FaAngleDown } from "react-icons/fa6";
import "../layout/index.css";
import { Link } from "react-scroll";
const Hero = () => {
  const [isLoading, setIsLoading] = useState(false);
  useLayoutEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading(true);
    }, 200);
    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <div
      className={`xl:max-w-[1240px] p-5 2xl:max-w-[1520px] w-full mx-auto xl:h-[80%] transition-opacity duration-500 ${
        isLoading ? "opacity-100" : "opacity-0"
      }`}
    >
      <div className="relative py-[40px] md:py-[150px] xl:py-[200px] flex flex-col items-center justify-center w-full">
        <div className="bg-bgHeroLogo192 opacity-100 xl:opacity-25 xl:absolute z-[0] top-[70px] h-[192px] w-[192px] object-cover md:h-[512px] md:w-[512px] md:bg-bgHeroLogo512" />
        <span className="disable-selection z-[1] mt-[50px] xl:mt-0 text-[30px] md:text-[55px] lg:text-[64px] font-bold text-textAvocadoLight">
          Nourishing the planet
        </span>
        <span className="disable-selection z-[1] text-[30px] md:text-[55px] lg:text-[64px] font-bold text-textAvocadoLight md:mt-[-25px]">
          with sustainable choices
        </span>
        <div className="flex flex-col z-[1] md:flex-row items-start md:items-center gap-1 md:gap-5">
          <span className="text-textAvocadoLight md:text-[25px]">
            Join us on our journey to reduce plastic waste!
          </span>
          <div className="flex  items-center gap-1 text-white border-b border-textAvocado cursor-pointer">
            <span className="md:text-[17px]">About Us</span>
            <MdArrowRightAlt className="text-[16px]" />
          </div>
        </div>
        <div className="flex items-center justify-center mt-[80%] md:mt-[40%] xl:mt-[20%]">
          <Link
            to="Products"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
          >
            <div className="rounded-full border border-textAvocado animate-bounce p-4 duration-150 text-textAvocado text-[20px]">
              <FaAngleDown />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
