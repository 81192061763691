import React from 'react';
import Marquee from 'react-fast-marquee';
import { Image } from 'antd';
import { marqueeItems } from '../../static/constant';
import '../layout/index.css';
const MarqueeLogos = () => {
  return (
    <div className='w-full bg-bgDarkGreen'>
        <Marquee
            autoFill 
            speed={40}
            loop={0}
        >
            <div className='flex items-center justify-center px-3 py-4'>
                {marqueeItems.map((item) => (
                    <div key={item.key} className='flex justify-center disable-selection items-center mx-6'>
                        <Image
                            src={item.logo}
                            preview={false}
                            style={{
                                height: item.height,
                                objectFit: 'cover',
                            }}
                        />
                    </div>
                ))}
            </div>
        </Marquee>
    </div>
  )
}

export default MarqueeLogos