import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Hero from "./Hero";
import TheOwners from "./TheOwners";
import MissionVision from "./MissionVision";
import Products from "./Products";
// import Services from "./Services";
import Roadmap from "./Roadmap";
const LandingPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingLanding, setIsLoadingLanding] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
      setIsLoadingLanding(true);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  return (
    <>
      {isLoading && (
        <motion.div
          className={`w-full justify-center items-center h-full`}
          variants={fadeInVariants}
          initial="visible"
          animate="hidden"
          transition={{
            ease: "linear",
            duration: 1,
            x: { duration: 0.5 },
          }}
          // animate={{
          // scale: [1, 2, 2, 1, 1],
          // rotate: [0, 0, 270, 270, 0],
          // }}
        >
          <div className="bg-bgHeroLogo192 md:bg-bgHeroLogo512 h-[192px] w-[192px] md:h-[512px] md:w-[512px] mx-auto mt-[90px]" />
        </motion.div>
      )}

      {isLoadingLanding && (
        <motion.div
          className={`transition-opacity duration-150 ${
            isLoadingLanding ? "opacity-100" : "opacity-0"
          }`}
        >
          <Hero />
          {/* <TheOwners /> */}
          <MissionVision />
          <Products />
          {/* <Services /> */}
          <Roadmap />
        </motion.div>
      )}
    </>
  );
};

export default LandingPage;
