import { Divider, Drawer, FloatButton, Image, Layout } from "antd";
import React, { useState, useEffect, useLayoutEffect } from "react";
import { BiMenu } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { IoIosArrowUp } from "react-icons/io";
import {
  navItems,
  drawerItems,
  backgroundImageDrawerStyle,
  footerImageItems,
} from "../../static/constant";
import { ReactLenis } from "@studio-freight/react-lenis";
import { motion } from "framer-motion";
import "./index.css";
import { Link, animateScroll as scroll } from "react-scroll";
const { Header, Content, Footer } = Layout;
const IndexLayout = ({ children }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [desktopDrawer, setDesktopDrawer] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const shouldSetBackground = window.scrollY > 250;
      setIsScrolled(shouldSetBackground);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  useLayoutEffect(() => {
    setTimeout(() => {
      setIsLoading(true);
    }, 1200);
  }, []);
  return (
    <ReactLenis root>
      <Layout className="bg-fixed bg-bgImage bg-repeat">
        <Header
          className={`flex ${
            isScrolled
              ? "bg-bgDarkGreen xl:bg-transparent"
              : "bg-bgDarkGreen xl:bg-transparent"
          } duration-500 ${
            isLoading ? "opacity-100" : "opacity-0"
          } md:h-[90px] justify-center max-w-[1920px] mx-auto w-full items-center`}
          // className={`flex bg-transparent duration-500 md:h-[90px] justify-center max-w-[1920px] mx-auto w-full items-center`}
          style={{
            position: "sticky",
            top: 0,
            zIndex: 100,
            width: "100%",
            // height: 90,
          }}
        >
          <div className="flex xl:hidden relative w-full justify-center items-center text-white">
            {openDrawer ? null : (
              <BiMenu
                className="text-[25px] absolute left-0 cursor-pointer xl:hidden"
                onClick={() => setOpenDrawer(!openDrawer)}
              />
            )}
            <span className="absolute font-bold text-[25px] text-textAvocadoLight">
              ecosource
            </span>
          </div>
          <motion.div
            className="hidden xl:flex w-full 2xl:mx-10 text-white justify-between items-center"
            initial={{ y: 0 }}
            animate={isScrolled ? { y: 30 } : { y: 0 }}
          >
            <div
              className={`disable-selection ${
                isScrolled ? "hidden" : "flex"
              } font-extrabold text-[36px] text-textAvocadoLight cursor-pointer`}
            >
              ecosource
            </div>
            <div
              className={` ${
                isScrolled && "flex justify-end items-end w-full"
              }`}
            >
              {isScrolled ? (
                <div
                  // className='bg-textAvocado p-3 rounded-full cursor-pointer falling-element text-darkAvocado hover:text-white duration-150 transform transition-transform hover:scale-110'
                  className="bg-textAvocado p-3 rounded-full cursor-pointer falling-element text-darkAvocado hover:text-white duration-150 transform transition-transform hover:scale-110"
                  onClick={() => setDesktopDrawer(!desktopDrawer)}
                >
                  <BiMenu className="text-[30px]  cursor-pointer no-scale" />
                </div>
              ) : (
                <div
                  className={`flex relative justify-center items-center gap-16 text-[15px] ${
                    isScrolled && "pulling-content"
                  }`}
                >
                  {navItems.map((item) => (
                    <div key={item.key}>
                      <div>
                        {item.navItem === "Home" ||
                        item.navItem === "Contact Us" ? (
                          <Link
                            to={item.navItem}
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}
                            onClick={() => {
                              item.navItem === "Contact Us"
                                ? window.open(
                                    "https://forms.gle/hGySUtY9j9gSjk188",
                                    "_blank"
                                  )
                                : scrollToTop();
                            }}
                          >
                            <span className="disable-selection cursor-pointer z-10 subpixel-antialiased hover:text-gray-500 duration-150 active">
                              {item.navItem}
                            </span>
                          </Link>
                        ) : (
                          <Link
                            to={item.navItem}
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}
                          >
                            <span className="disable-selection cursor-pointer z-10 subpixel-antialiased hover:text-gray-500 duration-150">
                              {item.navItem}
                            </span>
                          </Link>
                        )}
                      </div>
                    </div>
                  ))}
                  {/* <div>
                    <span
                      className="bg-textAvocado text-black py-2 px-4 disable-selection cursor-pointer hover:text-white duration-150 rounded-md"
                      onClick={() => {
                        window.open("https://www.google.com", "_blank");
                      }}
                    >
                      Sign-Up
                    </span>
                  </div> */}
                </div>
              )}
            </div>
          </motion.div>
          <Drawer
            closable={false}
            placement="left"
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            className=""
            style={backgroundImageDrawerStyle}
          >
            <div className="flex flex-col justify-center items-center gap-10 text-[16px] font-bold">
              <div className="relative w-full items-center flex justify-center">
                <span className="font-bold text-[25px]">ecosource</span>
                <IoClose
                  className="absolute right-0 text-[23px] cursor-pointer"
                  onClick={() => setOpenDrawer(false)}
                />
              </div>
              <Divider className="my-[-10px] bg-gray-400" />
              <div className="flex flex-col gap-10 justify-center items-center">
                {navItems.map((item) => (
                  <div key={item.key}>
                    <div>
                      {item.navItem === "Home" ||
                      item.navItem === "Contact Us" ? (
                        <Link
                          to={item.navItem}
                          spy={true}
                          smooth={true}
                          offset={0}
                          duration={500}
                          onClick={() => {
                            item.navItem === "Contact Us"
                              ? window.open(
                                  "https://forms.gle/hGySUtY9j9gSjk188",
                                  "_blank"
                                )
                              : scrollToTop();
                            setOpenDrawer(false);
                          }}
                        >
                          <span className="disable-selection cursor-pointer z-10 subpixel-antialiased hover:text-gray-500 duration-150 active">
                            {item.navItem}
                          </span>
                        </Link>
                      ) : (
                        <Link
                          to={item.navItem}
                          spy={true}
                          smooth={true}
                          offset={0}
                          duration={500}
                          onClick={() => {
                            setOpenDrawer(false);
                          }}
                        >
                          <span className="disable-selection cursor-pointer z-10 subpixel-antialiased hover:text-gray-500 duration-150">
                            {item.navItem}
                          </span>
                        </Link>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="absolute flex flex-col justify-center items-center mb-10 bottom-0 right-0 left-0">
              <span className="text-textAvocadoDrawer">
                +(63) 967 935 3074 / +(63) 917 865 6730
              </span>
              <span>support@ecosource.com.ph</span>
              <span>contact@bsy-ph.com</span>
            </div>
          </Drawer>
          <Drawer
            closable={false}
            placement="right"
            size="large"
            open={desktopDrawer}
            onClose={() => setDesktopDrawer(false)}
            // style={backgroundImageDrawerStyle}
          >
            <div className="flex flex-col w-full">
              <div className="w-full flex justify-end">
                <div
                  className="p-3 rounded-full border mx-10 border-textAvocado text-textAvocado hover:text-white hover:bg-textAvocado duration-150 cursor-pointer"
                  onClick={() => setDesktopDrawer(false)}
                >
                  <IoClose className="right-0 text-[27px] cursor-pointer" />
                </div>
              </div>
              <div className="mx-10 relative">
                {drawerItems.map((item) => (
                  <div key={item.key} className="mb-[-20px]">
                    {/* <span className='text-[80px] disable-selection hover:text-textAvocadoDrawer cursor-pointer duration-150 uppercase'>{item.item}</span> */}
                    {/* <Link to={item.item} spy={true} smooth={true} offset={0} duration={500} onClick={() => setDesktopDrawer(false)}><span className='text-[80px] disable-selection hover:text-textAvocadoDrawer cursor-pointer duration-150 uppercase'>{item.item}</span></Link> */}
                    <div>
                      {item.item === "Home" || item.item === "Contact Us" ? (
                        <Link
                          to={item.item}
                          spy={true}
                          smooth={true}
                          offset={0}
                          duration={500}
                          onClick={() => {
                            item.item === "Contact Us"
                              ? window.open(
                                  "https://forms.gle/hGySUtY9j9gSjk188",
                                  "_blank"
                                )
                              : scrollToTop();
                            setDesktopDrawer(false);
                          }}
                        >
                          <span className="text-[80px] disable-selection hover:text-textAvocadoDrawer cursor-pointer duration-150 uppercase">
                            {item.item}
                          </span>
                        </Link>
                      ) : (
                        <Link
                          to={item.item}
                          spy={true}
                          smooth={true}
                          offset={0}
                          duration={500}
                          onClick={() => {
                            setDesktopDrawer(false);
                          }}
                        >
                          <span className="text-[80px] disable-selection hover:text-textAvocadoDrawer cursor-pointer duration-150 uppercase">
                            {item.item}
                          </span>
                        </Link>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="absolute bottom-0 flex flex-col mx-10 mb-10 text-[15px]">
              <span className="text-textAvocadoDrawer">
                +(63) 967 935 3074 / +(63) 917 865 6730
              </span>
              <span>support@ecosource.com.ph</span>
              <span>contact@bsy-ph.com</span>
            </div>
          </Drawer>
        </Header>
        <Content className="max-w-[1920px] mx-auto w-full">{children}</Content>
        <Footer
          className={`bg-white max-w-[1920px] w-full mx-auto ${
            isLoading ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className="flex flex-col xl:grid xl:grid-cols-2 justify-center gap-x-5 gap-y-10 items-center my-10 mx-auto py-10 xl:max-w-[1240px] 2xl:max-w-[1520px]">
            <div className="w-full flex flex-col">
              <div className="flex gap-3 text-[20px] xl:text-[24px] text-darkAvocado">
                <span>TEAM.</span>
                <span>CUSTOMER.</span>
                <span>COMMUNITY.</span>
              </div>
              <div className="flex flex-col">
                <span className="text-[33px] md:text-[64px] font-semibold text-bgDarkGreen mb-[-10px] md:mb-[-30px] disable-selection">
                  We Work With
                </span>
                <span className="text-[33px] md:text-[64px] font-semibold text-bgDarkGreen disable-selection ">
                  The Best Partners
                </span>
              </div>
              <div className="max-w-[500px] w-full text-[16px]">
                <span>
                  At EcoSource, our success is built on meaningful partnerships
                  with businesses dedicated to the eco-friendly and green
                  movement. Collaborating with industry leadersg in sustainable
                  manufacturing, and partners in green logistics, we ensure that
                  every aspect of our operations aligns with our commitment to
                  environmental responsibility. These partnerships go beyond
                  transactions; they represent a shared vision for a sustainable
                  future. Together, we work tirelessly to offer high-quality
                  eco-friendly products while minimizing our collective
                  environmental footprint.
                </span>
              </div>
              <div className="mt-5">
                <button
                  className="px-7 py-3 text-white text-[16px] bg-bgDarkGreen"
                  onClick={() => {
                    window.open(
                      "https://forms.gle/hGySUtY9j9gSjk188",
                      "_blank"
                    );
                  }}
                >
                  Contact Us
                </button>
              </div>
            </div>
            <div className="flex flex-col md:grid md:grid-cols-2 justify-center items-center gap-y-10">
              {footerImageItems.map((item) => (
                // <div
                //   key={item.key}
                //   className={`${
                //     item.key === 1 ? "col-span-2" : "col-span-1"
                //   } disable-selection items-center flex justify-center`}
                // >
                //   <Image src={item.logo} preview={false} />
                // </div>
                <div
                  key={item.key}
                  className={`disable-selection w-[150px] md:w-auto h-auto items-center flex justify-center`}
                >
                  <Image src={item.logo} preview={false} />
                </div>
              ))}
            </div>
            <div className="mt-20">
              <span className="text-[16px]">
                © Copyright <span className="font-bold">2023 ECOSOURCE</span>
              </span>
            </div>
          </div>
        </Footer>
        <FloatButton.BackTop
          icon={<IoIosArrowUp />}
          style={{ color: "#000" }}
        />
      </Layout>
    </ReactLenis>
  );
};

export default IndexLayout;
