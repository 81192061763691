import React, { useEffect, useRef } from "react";
import TimelineObserver from "react-timeline-animation";
import "./landing.css";
const Timeline = ({ setObserver }) => {
  const timeline1 = useRef(null);
  const timeline2 = useRef(null);
  const timeline3 = useRef(null);
  // const timeline4 = useRef(null);

  useEffect(() => {
    setObserver(timeline1.current);
    setObserver(timeline2.current);
    setObserver(timeline3.current);
    // setObserver(timeline4.current);
  }, [setObserver]);

  return (
    <div className="wrapper w-1">
      <div
        id="timeline1"
        ref={timeline1}
        className="timeline min-h-[450px] md:min-h-[250px] lg:h-[450px] xl:h-[300px] xl:min-h-[0px]"
      />
      <div
        id="timeline2"
        ref={timeline2}
        className="timeline min-h-[450px] md:min-h-[250px] lg:h-[450px] xl:h-[300px] xl:min-h-[0px]"
      />
      <div
        id="timeline3"
        ref={timeline3}
        className="timeline min-h-[450px] md:min-h-[250px] lg:h-[450px] xl:h-[300px] xl:min-h-[0px]"
      />
      {/* <div id="timeline4" ref={timeline4} className="timeline min-h-[550px] md:min-h-[300px] lg:h-[500px] xl:h-[50px]" /> */}
    </div>
  );
};
const Roadmap = () => {
  return (
    <div
      className="bg-bgDarkGreen relative max-w-[1920px] w-full mx-auto py-32 xl:py-52 px-5 xl:px-0"
      id="Roadmap"
    >
      <div className="absolute z-10">
        <div className="relative w-[160px] h-[110px]">
          <div className="absolute w-[100px] h-[100px] left-[-50px] xl:left-[-20px] rounded-full bg-roadMapColorLightRounded bg-opacity-80 lg:bg-opacity-100" />
          <div className="absolute right-0 top-6 bottom-0 w-[50px] h-[50px] rounded-full bg-roadMapColorLightRounded bg-opacity-40" />
          <div className="absolute bottom-0 right-[50px] w-[30px] h-[30px] rounded-full bg-roadMapColorLightRounded bg-opacity-25" />
        </div>
      </div>
      <div className="absolute z-10 right-28 md:right-64 xl:right-96 top-[800px] xl:top-[550px]">
        <div className="relative w-[130px] h-[150px]">
          <div className="absolute w-[70px] h-[70px] right-0 rounded-full bg-roadMapColorLightRounded bg-opacity-80 lg:bg-opacity-60" />
          <div className="absolute left-0 top-14 bottom-0 w-[50px] h-[50px] rounded-full bg-roadMapColorLightRounded bg-opacity-40" />
          <div className="absolute bottom-0 right-[50px] w-[30px] h-[30px] rounded-full bg-roadMapColorLightRounded bg-opacity-25" />
        </div>
      </div>
      <div className="absolute z-10 left-28 md:left-96 bottom-[400px] md:bottom-[300px] xl:bottom-[550px]">
        <div className="relative w-[130px] h-[50px]">
          <div className="absolute right-0 bottom-0 w-[50px] z-[10] h-[50px] rounded-full bg-roadMapColorLightRounded" />
          <div className="absolute bottom-0 right-[40px] z-[5] w-[30px] h-[30px] rounded-full bg-roadMapColorLightRounded bg-opacity-25" />
        </div>
      </div>
      <div className="absolute z-10 right-0 xl:right-96 bottom-[0px] xl:bottom-[150px]">
        <div className="relative w-[200px] h-[200px]">
          <div className="absolute w-[150px] top-6 h-[150px] rounded-full bg-roadMapColorLightRounded bg-opacity-80 lg:bg-opacity-100" />
          <div className="absolute right-0 w-[50px] z-[10] h-[50px] rounded-full bg-roadMapColorLightRounded bg-opacity-50" />
          <div className="absolute right-14 w-[20px] z-[10] h-[20px] rounded-full bg-roadMapColorLightRounded bg-opacity-10" />
          <div className="absolute bottom-0 z-[5] w-[30px] h-[30px] rounded-full bg-roadMapColorLightRounded bg-opacity-25" />
        </div>
      </div>
      <div className="absolute z-10 px-0 xl:px-0 xl:w-full flex justify-center items-center">
        <TimelineObserver
          initialColor="#53634B"
          fillColor="#8BA17F"
          handleObserve={(setObserver) => (
            <Timeline className="timeline" setObserver={setObserver} />
          )}
          hasReverse={true}
        />
      </div>
      {/* <div className='xl:hidden grid grid-cols-2 grid-rows-4 w-full 2xl:max-w-[1520px] xl:max-w-[1240px] gap-y-16 xl:gap-y-0 gap-x-5 mx-auto'> */}
      <div className="xl:hidden px-5 xl:px-0 flex flex-col w-full 2xl:max-w-[1520px] xl:max-w-[1240px] gap-y-16 xl:gap-y-0 gap-x-5 mx-auto">
        <div className="flex flex-col flex-none text-white lg:max-w-[350px] xl:max-w-[500px] mx-auto w-full col-start-2 z-20 justify-center">
          <span className="xl:text-[80px] lg:text-[60px] text-[50px] font-bold bebas my-[-20px] xl:my-[-30px]">
            2023
          </span>
          <span className="text-lightGreenText text-[30px] lato font-bold">
            The Founding Year
          </span>
          <span className="text-[20px] font-light lato">
            In the pursuit of a shared vision for a more sustainable and
            eco-conscious future, the founders of Ecosource united their diverse
            expertise and passions. Driven by a collective desire to make a
            positive impact, the founders combined their skills to establish a
            company dedicated to the distribution of eco-friendly and
            sustainable products. Their shared commitment to environmental
            responsibility, coupled with their complementary skills, laid the
            foundation for a business that not only prioritizes financial
            success but also strives to contribute meaningfully to a greener,
            more sustainable world. Together, they embarked on the journey of
            creating a company that stands at the forefront of the eco-friendly
            product distribution landscape.
          </span>
        </div>
        <div className="flex flex-none flex-col text-white lg:max-w-[350px] xl:max-w-[500px] mx-auto w-full justify-center">
          <span className="z-20 xl:text-[80px] lg:text-[60px] text-[50px] font-bold bebas my-[-20px] xl:my-[-30px]">
            2024
          </span>
          <span className="z-20 text-lightGreenText text-[30px] lato font-bold">
            Year of Launching
          </span>
          <span className="z-20 text-[20px] font-light lato">
            Ecosource is launching BSY PRiis Noni Hair Color Shampoo in the
            Philippines. This revolutionary product, derived from pure Noni
            fruit extracts, offers a 5-minute hair color transformation.
            Developed through extensive research by health experts, it not only
            provides vibrant color but also brings anti-dandruff and nourishing
            properties. Powered by the potent BSY Noni Enzyme Extract, this
            shampoo is free from harmful chemicals, ensuring a safe and
            allergy-free coloring experience. Join us as we introduce a
            sustainable and effective hair care solution, prioritizing both
            natural beauty and consumer well-being.
          </span>
        </div>
        {/* <div className='flex flex-col text-white lg:max-w-[350px] xl:max-w-[500px] mx-auto w-full row-start-3 col-start-2 z-20 justify-center'>
          <span className='xl:text-[80px] lg:text-[60px] text-[50px] font-bold bebas my-[-20px] xl:my-[-30px]'>2025</span>
          <span className='text-lightGreenText text-[30px] lato font-bold'>The Founding Year</span>
          <span className='text-[20px] font-light lato'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</span>
        </div>
        <div className='flex flex-col text-white lg:max-w-[350px] xl:max-w-[500px] lg:mx-auto w-full row-start-4 xl:mr-0 z-20 justify-center'>
          <span className='xl:text-[80px] lg:text-[60px] text-[50px] font-bold bebas my-[-20px] xl:my-[-30px]'>2030</span>
          <span className='text-lightGreenText text-[30px] lato font-bold'>The Founding Year</span>
          <span className='text-[20px] font-light lato'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</span>
        </div> */}
      </div>
      <div className="hidden xl:grid xl:grid-cols-2 w-full 2xl:max-w-[1520px] xl:max-w-[1240px] gap-y-16 xl:gap-y-0 gap-x-5 mx-auto">
        <div className="flex flex-col xl:gap-80 xl:mt-96">
          <div className="flex flex-none flex-col text-white lg:max-w-[350px] xl:max-w-[500px] mx-auto w-full justify-center">
            <span className="z-20 xl:text-[80px] lg:text-[60px] text-[50px] font-bold bebas my-[-20px] xl:my-[-30px]">
              2024
            </span>
            <span className="z-20 text-lightGreenText text-[30px] lato font-bold">
              Year of Launching
            </span>
            <span className="z-20 text-[20px] font-light lato">
              Ecosource is launching BSY PRiis Noni Hair Color Shampoo in the
              Philippines. This revolutionary product, derived from pure Noni
              fruit extracts, offers a 5-minute hair color transformation.
              Developed through extensive research by health experts, it not
              only provides vibrant color but also brings anti-dandruff and
              nourishing properties. Powered by the potent BSY Noni Enzyme
              Extract, this shampoo is free from harmful chemicals, ensuring a
              safe and allergy-free coloring experience. Join us as we introduce
              a sustainable and effective hair care solution, prioritizing both
              natural beauty and consumer well-being.
            </span>
          </div>
          {/* <div className='flex flex-col flex-none text-white lg:max-w-[350px] xl:max-w-[500px] lg:mx-auto w-full row-start-4 xl:mr-0 z-20 justify-center'>
            <span className='xl:text-[80px] lg:text-[60px] text-[50px] font-bold bebas my-[-20px] xl:my-[-30px]'>2030</span>
            <span className='text-lightGreenText text-[30px] lato font-bold'>The Founding Year</span>
            <span className='text-[20px] font-light lato'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</span>
          </div> */}
        </div>
        <div className="flex flex-col xl:gap-80">
          <div className="flex flex-col flex-none text-white lg:max-w-[350px] xl:max-w-[500px] mx-auto w-full col-start-2 z-20 justify-center">
            <span className="xl:text-[80px] lg:text-[60px] text-[50px] font-bold bebas my-[-20px] xl:my-[-30px]">
              2023
            </span>
            <span className="text-lightGreenText text-[30px] lato font-bold">
              The Founding Year
            </span>
            <span className="text-[20px] font-light lato">
              In the pursuit of a shared vision for a more sustainable and
              eco-conscious future, the founders of Ecosource united their
              diverse expertise and passions. Driven by a collective desire to
              make a positive impact, the founders combined their skills to
              establish a company dedicated to the distribution of eco-friendly
              and sustainable products. Their shared commitment to environmental
              responsibility, coupled with their complementary skills, laid the
              foundation for a business that not only prioritizes financial
              success but also strives to contribute meaningfully to a greener,
              more sustainable world. Together, they embarked on the journey of
              creating a company that stands at the forefront of the
              eco-friendly product distribution landscape.
            </span>
          </div>
          {/* <div className='flex flex-col text-white lg:max-w-[350px] xl:max-w-[500px] mx-auto w-full row-start-3 col-start-2 z-20 justify-center'>
            <span className='xl:text-[80px] lg:text-[60px] text-[50px] font-bold bebas my-[-20px] xl:my-[-30px]'>2025</span>
            <span className='text-lightGreenText text-[30px] lato font-bold'>The Founding Year</span>
            <span className='text-[20px] font-light lato'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
